'use client';

import { useEffect, useState } from 'react';

// Add missing type declarations
declare global {
  interface Window {
    _lastUserActivity: number;
  }
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export default function ErrorBoundary({ children }: ErrorBoundaryProps) {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    // Only run in browser
    if (typeof window === 'undefined') return;
    
    // Detect if page is in broken state with too many CSP warnings
    let cspErrorCount = 0;
    const maxAllowedCspErrors = 5;
    const reloadDelay = 2000; // 2 seconds
    let reloadScheduled = false;
    
    // Store timeouts to clear them if needed
    const timeouts: NodeJS.Timeout[] = [];
    
    // First, try to fix nonced scripts
    const fixNoncedScripts = () => {
      try {
        // Get the nonce from global or meta tag
        const nonce = window.__CSP_NONCE__ || document.querySelector('meta[name="csp-nonce"]')?.getAttribute('content') || '';
        
        if (nonce) {
          // Apply nonce to all inline scripts that don't have it
          document.querySelectorAll('script:not([nonce]):not([src])').forEach(script => {
            script.setAttribute('nonce', nonce);
          });
        }
      } catch (e) {
        console.warn('Failed to fix nonced scripts:', e);
      }
    };
    
    // Run fix immediately
    fixNoncedScripts();
    
    // Then schedule another fix in 1 second for scripts loaded after initial page load
    setTimeout(fixNoncedScripts, 1000);

    // Listen for unhandled errors
    const errorHandler = (event: ErrorEvent) => {
      // Check if it's a connection closed error
      if (
        event.error?.message?.includes('Connection closed') ||
        event.message?.includes('Connection closed')
      ) {
        console.log('Connection closed error caught, reloading page');
        scheduleReload();
        
        // Prevent default to avoid console errors
        event.preventDefault();
      }
      
      // For CSP errors, just log and prevent default - don't reload
      if (event.message?.includes('Content Security Policy')) {
        console.log(`CSP error caught: ${event.message}`);
        
        // Attempt to fix scripts if it's CSP related (but don't reload)
        fixNoncedScripts();
        
        // Prevent default to avoid console errors
        event.preventDefault();
      }
    };

    // Handle unhandled promise rejections
    const rejectionHandler = (event: PromiseRejectionEvent) => {
      if (
        event.reason?.message?.includes('Connection closed') ||
        (typeof event.reason === 'string' && event.reason.includes('Connection closed'))
      ) {
        console.log('Connection closed in promise rejection, reloading page');
        scheduleReload();
        
        // Prevent default to avoid console errors
        event.preventDefault();
      }
    };
    
    // Helper function to schedule a page reload
    const scheduleReload = () => {
      if (!reloadScheduled) {
        reloadScheduled = true;
        
        // Show error message to user briefly
        setHasError(true);
        setErrorMessage("Connection issue detected. Refreshing page...");
        
        const timeout = setTimeout(() => {
          try {
            // Clear session storage items that might be causing issues
            sessionStorage.removeItem('nextReactQueryState');
            
            // Reload page
            window.location.reload();
          } catch (e) {
            console.error('Error reloading page:', e);
          }
        }, reloadDelay);
        
        timeouts.push(timeout);
      }
    };

    window.addEventListener('error', errorHandler);
    window.addEventListener('unhandledrejection', rejectionHandler);
    
    // Add periodic check for stalled connections
    const connectionCheckInterval = setInterval(() => {
      // If the page has been loaded for more than 5 minutes without activity
      const lastActivity = window._lastUserActivity || Date.now();
      const timeSinceActivity = Date.now() - lastActivity;
      
      // If 5+ minutes with no activity, check connection
      if (timeSinceActivity > 5 * 60 * 1000) {
        // Simple ping to check if connection is alive
        fetch('/api/ping', { 
          method: 'HEAD',
          cache: 'no-store'
        })
        .catch(err => {
          console.log('Connection check failed, reloading page:', err);
          scheduleReload();
        });
      }
    }, 60 * 1000); // Check every minute
    
    // Track user activity
    const updateActivity = () => {
      window._lastUserActivity = Date.now();
    };
    
    // Listen for user interactions
    window.addEventListener('click', updateActivity);
    window.addEventListener('keydown', updateActivity);
    window.addEventListener('mousemove', updateActivity);
    window.addEventListener('scroll', updateActivity);
    
    // Set initial activity
    updateActivity();
    
    // Clean up
    return () => {
      window.removeEventListener('error', errorHandler);
      window.removeEventListener('unhandledrejection', rejectionHandler);
      window.removeEventListener('click', updateActivity);
      window.removeEventListener('keydown', updateActivity);
      window.removeEventListener('mousemove', updateActivity);
      window.removeEventListener('scroll', updateActivity);
      
      clearInterval(connectionCheckInterval);
      timeouts.forEach(timeout => clearTimeout(timeout));
    };
  }, []);

  if (hasError) {
    return (
      <div className="error-boundary-message">
        <div className="text-red-700 bg-red-100 p-4 rounded-md">
          {errorMessage || "An error occurred. Refreshing page..."}
        </div>
        {children}
      </div>
    );
  }

  return <>{children}</>;
}