"use client";

import React, { useEffect, useState } from "react";
import { Sunrise, Sunset } from "lucide-react";
import SunCalc from "suncalc";

interface DateInfoBarProps {
  locationData: {
    latitude: number;
    longitude: number;
  } | null;
}

export default function DateInfoBar({ locationData }: DateInfoBarProps) {
  const [currentDate, setCurrentDate] = useState("");
  const [sunriseTime, setSunriseTime] = useState("--:--");
  const [sunsetTime, setSunsetTime] = useState("--:--");
  const [daylightDuration, setDaylightDuration] = useState({ hours: 0, minutes: 0 });
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      const date = now.toLocaleDateString("en-US", {
        weekday: "long",
        day: "numeric",
        month: "long",
      });
      setCurrentDate(date.charAt(0).toUpperCase() + date.slice(1));

      const time = now.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false
      });
      setCurrentTime(time);
    };

    updateDateTime();
    const timer = setInterval(updateDateTime, 60000); // Update every minute
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!locationData) {
      setSunriseTime("--:--");
      setSunsetTime("--:--");
      setDaylightDuration({ hours: 0, minutes: 0 });
      return;
    }

    const now = new Date();
    const times = SunCalc.getTimes(
      now,
      locationData.latitude,
      locationData.longitude
    );

    if (!times.sunrise || !times.sunset) {
      setSunriseTime("--:--");
      setSunsetTime("--:--");
      setDaylightDuration({ hours: 0, minutes: 0 });
      return;
    }

    const sunriseStr = formatTime(times.sunrise);
    const sunsetStr = formatTime(times.sunset);

    const durationMs = times.sunset.getTime() - times.sunrise.getTime();
    const hours = Math.floor(durationMs / (1000 * 60 * 60));
    const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));

    setSunriseTime(sunriseStr);
    setSunsetTime(sunsetStr);
    setDaylightDuration({ hours, minutes });
  }, [locationData]);

  function formatTime(date: Date): string {
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }

  if (!locationData) return null;

  return (
    <div className="w-full bg-sky-700 text-white">
      <div className="container mx-auto px-4 py-2 md:py-1.5">
        {/* Mobile View (stacked) */}
        <div className="md:hidden flex flex-col items-center gap-2 text-sm">
          <div className="flex items-center gap-2">
            <span>{currentDate}</span>
            <span>•</span>
            <span>{currentTime}</span>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-1">
              <Sunrise className="h-4 w-4 text-yellow-400" />
              <span>{sunriseTime}</span>
            </div>
            <div className="flex items-center gap-1">
              <Sunset className="h-4 w-4 text-orange-400" />
              <span>{sunsetTime}</span>
            </div>
          </div>
          <div className="text-center text-xs text-gray-300">
            Διάρκεια φωτός ημέρας: {daylightDuration.hours} ώρες, {daylightDuration.minutes} λεπτά
          </div>
        </div>

        {/* Desktop View (horizontal) */}
        <div className="hidden md:flex flex-wrap items-center justify-center gap-4 text-sm">
          <div className="flex items-center gap-4">
            <span>{currentDate}</span>
            <div className="flex items-center gap-2">
              <span>⏰</span>
              <span>{currentTime}</span>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-1">
              <Sunrise className="h-4 w-4 text-yellow-400" />
              <span>{sunriseTime}</span>
            </div>
            <div className="flex items-center gap-1">
              <Sunset className="h-4 w-4 text-orange-400" />
              <span>{sunsetTime}</span>
            </div>
            <div>
              <span>Διάρκεια φωτός ημέρας: {daylightDuration.hours} ώρες, {daylightDuration.minutes} λεπτά</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};