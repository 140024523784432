"use client";

import React, { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { MapPin, Loader2, RefreshCw } from "lucide-react";

interface LocationData {
  id: number;
  guid: string | null;
  slug: string;
  name: string;
  latitude: number;
  longitude: number;
  elevation: number;
  description: string;
  country: {
    code: string;
    name: string;
    slug: string;
  };
  seo_url: string;
  category: {
    id: number;
    name: string;
  };
  // Keep backward compatibility with existing stored data
  place_name_en?: string;
  place_name_el?: string;
  country_name_en?: string;
  country_name_el?: string;
  place_slug?: string;
  country_slug?: string;
  country_code?: string;
  admin_divisions?: Array<{
    level: number;
    level_name_en: string;
    level_name_el: string;
    division_slug: string;
    division_name: string;
    division_name_en: string;
    division_name_el: string;
    url_order: number;
  }>;
}

export default function GeolocationBar() {
  const router = useRouter();
  const [locationData, setLocationData] = useState<LocationData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Function to calculate distance between two points using Haversine formula
  const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
    const R = 6371; // Radius of the earth in km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a =
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c; // Distance in km
    return distance;
  };

  // Load saved location data from localStorage on mount and automatically update geolocation
  useEffect(() => {
    if (typeof window !== "undefined") {
      const savedLocation = localStorage.getItem("physicalLocationData");
      if (savedLocation) {
        setLocationData(JSON.parse(savedLocation));
      }

      // Automatically update geolocation when component mounts
      // Use false for shouldRedirect to prevent page redirection
      requestLocation(false);
    }
  }, []);

  // Function to request user's location and fetch nearest place
  const requestLocation = (shouldRedirect = false) => {
    setIsLoading(true);
    setError(null);

    if (typeof window !== "undefined" && "geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            const { latitude, longitude } = position.coords;
            const baseUrl = process.env.NEXT_PUBLIC_API_URL || "https://kairos.gr";
            const language = document.documentElement.lang || "el";

            // Implement retry mechanism for resilience
            const MAX_RETRIES = 3;
            let attempt = 0;
            let success = false;
            let lastError: Error | null = null;

            while (attempt < MAX_RETRIES && !success) {
              try {
                // Ensure trailing slash is present in the API URL to match backend routes
                const apiUrl = `${baseUrl}/api/geography/nearest-place/?latitude=${latitude}&longitude=${longitude}`;
                console.log(`Fetching nearest place from: ${apiUrl} (attempt ${attempt + 1}/${MAX_RETRIES})`);

                // First try to get from localStorage cache for instant response
                if (attempt === 0) {
                  const cachedLocationStr = localStorage.getItem("physicalLocationDataCache");
                  if (cachedLocationStr) {
                    try {
                      const cachedLocationData = JSON.parse(cachedLocationStr);
                      // Check if cached data is for a nearby location (within 1km)
                      const cachedLat = cachedLocationData.latitude;
                      const cachedLon = cachedLocationData.longitude;

                      if (cachedLat && cachedLon) {
                        const distance = calculateDistance(latitude, longitude, cachedLat, cachedLon);
                        if (distance < 1) { // Less than 1km
                          console.log(`Using cached location data (${distance.toFixed(2)}km away)`);
                          const result = cachedLocationData as LocationData;
                          localStorage.setItem("physicalLocationData", JSON.stringify(result));
                          localStorage.setItem("placeData", JSON.stringify(result));
                          setLocationData(result);

                          // Still make API request in background, but don't wait for it
                          fetch(apiUrl, {
                            headers: {
                              Accept: "application/json",
                              "Accept-Language": language,
                            },
                            cache: "no-cache"
                          }).then(response => {
                            if (response.ok) return response.json();
                            return null;
                          }).then(freshData => {
                            if (freshData) {
                              localStorage.setItem("physicalLocationData", JSON.stringify(freshData));
                              localStorage.setItem("placeData", JSON.stringify(freshData));
                              localStorage.setItem("physicalLocationDataCache", JSON.stringify(freshData));
                              setLocationData(freshData);
                              // Dispatch event to notify other parts of the app
                              window.dispatchEvent(new Event("placeDataChanged"));
                            }
                          }).catch(err => console.error("Background refresh error:", err));

                          // Only redirect if shouldRedirect is true (for initial location finding)
                          if (shouldRedirect) {
                            const locationUrl = getLocationUrl(result);
                            router.push(locationUrl);
                          }

                          success = true;
                          break; // Exit the retry loop
                        }
                      }
                    } catch (e) {
                      console.error("Error parsing cached location data:", e);
                      // Continue with API request
                    }
                  }
                }

                // Use Next.js cache with stale-while-revalidate strategy
                const response = await fetch(apiUrl, {
                  headers: {
                    Accept: "application/json",
                    "Accept-Language": language,
                  },
                  next: {
                    revalidate: 300 // Cache for 5 minutes
                  }
                });

                if (!response.ok) {
                  const errorText = await response.text();
                  throw new Error(`HTTP Error! Status: ${response.status}. Response: ${errorText.substring(0, 100)}`);
                }

                const result = await response.json() as LocationData;
                localStorage.setItem("physicalLocationData", JSON.stringify(result));
                localStorage.setItem("placeData", JSON.stringify(result));
                localStorage.setItem("physicalLocationDataCache", JSON.stringify(result));
                setLocationData(result);

                // Dispatch event to notify other parts of the app
                window.dispatchEvent(new Event("placeDataChanged"));

                // Only redirect if shouldRedirect is true (for initial location finding)
                if (shouldRedirect) {
                  const locationUrl = getLocationUrl(result);
                  router.push(locationUrl);
                }

                success = true;
                break; // API call succeeded, exit the retry loop

              } catch (err) {
                lastError = err as Error;
                attempt++;
                console.error(`Attempt ${attempt}/${MAX_RETRIES} failed:`, err);

                // Only wait if we're going to retry
                if (attempt < MAX_RETRIES) {
                  // Exponential backoff: 500ms, 1000ms, etc.
                  const delay = 500 * Math.pow(2, attempt - 1);
                  console.log(`Waiting ${delay}ms before retry...`);
                  await new Promise(r => setTimeout(r, delay));
                }
              }
            }

            // If all retries failed
            if (!success && lastError) {
              setError(`Αποτυχία εύρεσης τοποθεσίας: ${(lastError as Error).message || "Άγνωστο σφάλμα"}`);
            }
          } catch (err) {
            setError(`Αποτυχία εύρεσης τοποθεσίας: ${(err as Error).message || "Άγνωστο σφάλμα"}`);
          } finally {
            setIsLoading(false);
          }
        },
        (geoError) => {
          console.error("Geolocation error:", geoError);
          // Provide more user-friendly error messages based on error code
          if (geoError.code === 1) {
            setError(`Η πρόσβαση στην τοποθεσία απορρίφθηκε. Παρακαλώ επιτρέψτε την πρόσβαση στην τοποθεσία στις ρυθμίσεις του προγράμματος περιήγησής σας.`);
          } else if (geoError.code === 2) {
            setError(`Η τοποθεσία δεν είναι διαθέσιμη. Παρακαλώ δοκιμάστε ξανά αργότερα.`);
          } else if (geoError.code === 3) {
            setError(`Το αίτημα τοποθεσίας έληξε. Παρακαλώ δοκιμάστε ξανά.`);
          } else {
            setError(`Σφάλμα τοποθεσίας: ${geoError.message}`);
          }
          setIsLoading(false);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      setError("Η υπηρεσία τοποθεσίας δεν είναι διαθέσιμη.");
      setIsLoading(false);
    }
  };

  // Helper function to get the URL for a location
  const getLocationUrl = (location: LocationData): string => {
    // If we have a direct seo_url
    if (location.seo_url) {
      // Remove trailing slash if present and ensure format is /weather/slug, not /weather/country/slug
      return location.seo_url.replace(/\/$/, '').replace(/\/weather\/[^\/]+\//, '/weather/');
    }

    // If no seo_url, construct it from slug
    return `/weather/${location.slug || location.place_slug}`;
  };

  return (
    <div className="w-full bg-sky-200 border-b border-green-400">
      <div className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8 py-2">
        <div className="flex items-center justify-between">
          {/* Fixed height container to prevent layout shifts */}
          <div className="flex items-center space-x-2 overflow-hidden h-8">
            {isLoading ? (
              <div className="flex items-center space-x-2 text-sky-900">
                <Loader2 className="animate-spin h-5 w-5 flex-shrink-0" />
                <span className="text-sm truncate">Εντοπισμός...</span>
              </div>
            ) : error ? (
              <div className="flex items-center space-x-2 text-red-700">
                <MapPin className="h-4 w-4 flex-shrink-0" />
                <span className="text-sm truncate">{error}</span>
              </div>
            ) : locationData ? (
              <div className="flex items-center space-x-2 overflow-hidden">
                <MapPin className="h-4 w-4 text-sky-900 flex-shrink-0" />
                <Link
                  href={getLocationUrl(locationData)}
                  className="text-sky-900 hover:text-sky-950 font-medium text-sm truncate"
                  prefetch={true} /* Preload this data */
                >
                  {locationData.name || (document.documentElement.lang === "el"
                    ? locationData.place_name_el
                    : locationData.place_name_en)}

                  {locationData.country && locationData.country.name &&
                    `, ${locationData.country.name}`}

                  {locationData.admin_divisions && locationData.admin_divisions.length > 0
                    ? `, ${
                        document.documentElement.lang === "el"
                          ? locationData.admin_divisions[1]?.division_name_el
                          : locationData.admin_divisions[1]?.division_name_en
                      }`
                    : ""}

                  <span className="ml-2 text-xs text-sky-800">
                    ({locationData.elevation}μ)
                  </span>
                </Link>
              </div>
            ) : (
              /* Placeholder to maintain layout even when no data */
              <div className="h-5 min-w-[100px]"></div>
            )}
          </div>

          <button
            onClick={() => {
              // Pass true for redirecting only when initially finding location
              // Pass false when updating to prevent redirection
              const shouldRedirect = !locationData;
              requestLocation(shouldRedirect);
            }}
            disabled={isLoading}
            className={`flex items-center space-x-1 px-3 py-1 rounded-md text-sm transition-colors flex-shrink-0 ml-2 ${
              !locationData && !isLoading
                ? "bg-sky-700 text-white hover:bg-sky-800"
                : "text-sky-800 hover:text-sky-950 bg-sky-300/50 hover:bg-sky-300"
            }`}
          >
            {locationData ? (
              <>
                <RefreshCw className="h-4 w-4" />
                <span>Ενημέρωση</span>
              </>
            ) : (
              <>
                <MapPin className="h-4 w-4" />
                <span>Εύρεση Τοποθεσίας</span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}