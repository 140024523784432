'use client';
// File: app/components/Header/SearchBar.tsx

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Search, X, MapPin, Loader2 } from 'lucide-react';
import Link from 'next/link';

interface SearchResult {
  name: string;
  name_el?: string;
  name_en?: string;
  display_name?: string;
  slug: string;
  guid: string;
  country_slug?: string;
  region_name?: string;
  municipality_name?: string;
  url?: string;
  latitude?: number;
  longitude?: number;
  category_id?: number;
}

interface ApiSearchResult {
  name: string;
  name_el?: string;
  name_en?: string;
  display_name?: string;
  slug: string;
  guid: string;
  country_slug?: string;
  region_name?: string;
  municipality_name?: string;
  url?: string;
  url_simple?: string; // Add explicit type for url_simple
  latitude?: number;
  longitude?: number;
  category_id?: number;
  // Use a more specific index signature that avoids 'any'
  [key: string]: string | number | boolean | undefined | null | string[] | number[];
}

interface SearchResponse {
  places: ApiSearchResult[];
  cached?: boolean;
  query_time_ms?: number;
  error?: string;
}

export default function SearchBar() {
  // State management
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [language, setLanguage] = useState('el'); // Default language

  // Refs for click outside detection and input focus
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  // Determine current language based on document or navigator
  useEffect(() => {
    // Try to detect language from document or navigator
    const docLang = document.documentElement.lang;
    if (docLang && (docLang.startsWith('el') || docLang.startsWith('en'))) {
      setLanguage(docLang.startsWith('el') ? 'el' : 'en');
    } else if (navigator.language) {
      setLanguage(navigator.language.startsWith('el') ? 'el' : 'en');
    }
  }, []);

  // Helper function to ensure string type
  const ensureString = (value: any): string | undefined => {
    if (value === undefined || value === null) return undefined;
    return String(value);
  };

  // Memoized fetch function to prevent recreating on each render
  const fetchResults = useCallback(async () => {
    if (!query.trim()) return;

    // Cancel any ongoing request
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    // Create a new abort controller for this request
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    setLoading(true);
    setError(null);

    try {
      // Define the main endpoint with explicit language parameter for better cache efficiency
      const mainEndpoint = `/api/geography/search/?q=${encodeURIComponent(query.trim())}&lang=${language}`;
      const fallbackEndpoint = `/api/search/places/?q=${encodeURIComponent(query.trim())}`;

      // Try the main endpoint first, then fallback if needed
      let response = null;
      let usedEndpoint = '';

      try {
        // First try the optimized endpoint with explicit language
        const resp = await fetch(mainEndpoint, {
          signal,
          headers: { 'Accept-Language': language }
        });

        if (resp.ok) {
          response = resp;
          usedEndpoint = mainEndpoint;
        }
      } catch (error) {
        // If it's an abort error, just return
        if (error instanceof DOMException && error.name === 'AbortError') {
          return;
        }
        // Otherwise continue to fallback endpoint
      }

      // If main endpoint failed, try fallback
      if (!response) {
        try {
          const resp = await fetch(fallbackEndpoint, { signal });
          if (resp.ok) {
            response = resp;
            usedEndpoint = fallbackEndpoint;
          }
        } catch (error) {
          // If it's an abort error, just return
          if (error instanceof DOMException && error.name === 'AbortError') {
            return;
          }
          // Otherwise let the error be caught below
        }
      }

      if (!response) {
        throw new Error('All search endpoints failed');
      }

      // Only log in development
      if (process.env.NODE_ENV === 'development') {
        console.log(`Search using: ${usedEndpoint}`);
      }

      const responseData: SearchResponse = await response.json();

      // Check if we have places in the response
      if (!responseData.places || !Array.isArray(responseData.places)) {
        console.error('Invalid response format:', responseData);
        setError(responseData.error || 'Invalid response format from server');
        setResults([]);
        return;
      }

      // Process results with explicit typing at each step
      const processedResults: SearchResult[] = [];

      for (const apiResult of responseData.places) {
        // Ensure country_slug has at least a default value
        const countrySlug = ensureString(apiResult.country_slug) || 'unknown';

        // For wfy24.com, prefer the simple URL format (without country slug)
        // Use type-safe approach for url_simple which might be a number in the API
        const url = ensureString(apiResult.url_simple) || ensureString(apiResult.url) || `/weather/${apiResult.slug}`;

        // Use display_name from API if available, or fall back to appropriate language
        let displayName = ensureString(apiResult.display_name) || apiResult.name;

        // Try to get the language-specific name if available
        if (language === 'el' && apiResult.name_el) {
          displayName = apiResult.name_el;
        } else if (language === 'en' && apiResult.name_en) {
          displayName = apiResult.name_en;
        }

        // Create a fresh SearchResult object with only the properties we need
        const searchResult: SearchResult = {
          name: displayName,
          name_el: ensureString(apiResult.name_el),
          name_en: ensureString(apiResult.name_en),
          display_name: ensureString(apiResult.display_name),
          slug: apiResult.slug,
          guid: apiResult.guid,
          country_slug: countrySlug,
          region_name: ensureString(apiResult.region_name),
          municipality_name: ensureString(apiResult.municipality_name),
          url: url,
          latitude: typeof apiResult.latitude === 'number' ? apiResult.latitude : undefined,
          longitude: typeof apiResult.longitude === 'number' ? apiResult.longitude : undefined,
          category_id: typeof apiResult.category_id === 'number' ? apiResult.category_id : undefined
        };

        processedResults.push(searchResult);
      }

      setResults(processedResults);
      setShowResults(true);
    } catch (error) {
      // Only set error if not an abort error
      if (!(error instanceof DOMException && error.name === 'AbortError')) {
        console.error('Search error:', error);
        setError(error instanceof Error ? error.message : 'Search failed');
        setResults([]);
      }
    } finally {
      // Only update loading state if not aborted
      if (abortControllerRef.current) {
        setLoading(false);
      }
    }
  }, [query, language]);

  // Debounced search with adaptive delay
  useEffect(() => {
    // Use shorter delay for longer queries (likely more specific)
    const delay = query.trim().length >= 4 ? 200 : 300;
    
    const timer = setTimeout(() => {
      if (query.trim().length > 1) {
        fetchResults();
      } else {
        setResults([]);
        setError(null);
      }
    }, delay);

    return () => {
      clearTimeout(timer);
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [query, fetchResults, language]);

  // Click outside handler
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setShowResults(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handle input clear
  const handleClear = () => {
    setQuery('');
    setResults([]);
    setError(null);
    setShowResults(false);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // Handle result click
  const handleResultClick = () => {
    setShowResults(false);
  };

  // Get placeholder text based on language
  const getPlaceholder = () => {
    return language === 'el' ? 'Αναζήτηση τοποθεσίας...' : 'Search for a location...';
  };

  // Get loading and error messages based on language
  const getLoadingText = () => language === 'el' ? 'Φόρτωση...' : 'Loading...';
  const getErrorText = () => language === 'el' ? `Σφάλμα: ${error}` : `Error: ${error}`;
  const getNoResultsText = () => {
    return language === 'el' ? 'Δεν βρέθηκαν αποτελέσματα' : 'No results found';
  };

  // Format coordinates based on language preference
  const formatCoordinates = (lat?: number, lng?: number) => {
    if (lat === undefined || lng === undefined) return null;
    return `${lat.toFixed(4)}, ${lng.toFixed(4)}`;
  };

  return (
    <div
      className="relative w-full"
      ref={searchRef}
    >
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <Search className="w-5 h-5 text-gray-400" />
        </div>

        <input
          ref={inputRef}
          type="search"
          className="w-full p-3 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
          placeholder={getPlaceholder()}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onFocus={() => query.trim() && setShowResults(true)}
          aria-label="Search for a location"
        />

        {query && (
          <button
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={handleClear}
            aria-label="Clear search"
          >
            <X className="w-5 h-5 text-gray-400 hover:text-gray-700" />
          </button>
        )}
      </div>

      {/* Results dropdown */}
      {showResults && (
        <div className="absolute z-10 w-full mt-2 bg-white border border-gray-200 rounded-lg shadow-lg max-h-96 overflow-y-auto">
          {loading ? (
            <div className="p-4 text-center text-gray-500 flex items-center justify-center">
              <Loader2 className="w-5 h-5 mr-2 animate-spin" />
              {getLoadingText()}
            </div>
          ) : error ? (
            <div className="p-4 text-center text-red-500">
              {getErrorText()}
            </div>
          ) : results.length > 0 ? (
            <ul className="py-2">
              {results.map((result) => (
                <li key={`${result.slug}-${result.guid || 'no-guid'}`} className="px-1">
                  <Link
                    href={result.url || '#'}
                    className="flex items-start gap-3 p-3 hover:bg-gray-100 rounded-md transition-colors"
                    onClick={handleResultClick}
                  >
                    <MapPin className="w-5 h-5 text-blue-500 mt-0.5 flex-shrink-0" />
                    <div className="flex-1 min-w-0">
                      <div className="font-medium text-gray-900 truncate">{result.name}</div>
                      <div className="text-xs text-gray-600 flex flex-col">
                        {(result.municipality_name || result.region_name) && (
                          <span className="truncate">
                            {[
                              result.municipality_name,
                              result.region_name
                            ].filter(Boolean).join(", ")}
                          </span>
                        )}
                        {result.latitude && result.longitude && (
                          <span className="text-gray-400 truncate">
                            {formatCoordinates(result.latitude, result.longitude)}
                          </span>
                        )}
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          ) : query.trim().length > 1 ? (
            <div className="p-4 text-center text-gray-500">
              {getNoResultsText()}
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
}