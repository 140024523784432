"use client";
import React from "react";
import WeatherIcon from "./../WeatherIcon";
import { getBaseUrl, getSiteDomain } from "@/app/lib/siteConfig";

export default function Logo() {
  const baseUrl = getBaseUrl();
  const domain = getSiteDomain();
  
  // Determine site name based on domain
  const siteName = domain.includes('kairos.gr') ? 'Kairos.gr' : 'WFY24.com';

  return (
    <a
      href={baseUrl}
      className="group flex items-center gap-3 transition-all duration-300 hover:opacity-90"
      rel="noopener noreferrer"
    >
      {/* Weather Icon on the left */}
      <div className="w-10 h-10">
        <WeatherIcon
          weatherType="clear"
          size="md"
          className="opacity-80"
        />
      </div>

      {/* Text */}
      <div className="text-[#00008B] text-3xl font-bold tracking-wide transition-transform duration-300 group-hover:scale-105">
        {siteName}
      </div>
    </a>
  );
}