import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\",\"greek\"],\"display\":\"swap\",\"preload\":true,\"fallback\":[\"system-ui\",\"-apple-system\",\"Segoe UI\",\"Arial\",\"sans-serif\"],\"adjustFontFallback\":true,\"variable\":\"--font-inter\",\"weight\":[\"400\",\"500\",\"600\",\"700\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/ErrorBoundary.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/components/Header/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NonceProvider"] */ "/app/src/app/lib/nonceContext.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/globals.css");
