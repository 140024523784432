"use client";

import React, { useState, useEffect } from "react";
import GeolocationBar from "./GeolocationBar";
import DateInfoBar from "./DateInfoBar";
import Logo from "./Logo";
import SearchBar from "./SearchBar";

export default function Header() {
  const [locationData, setLocationData] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const savedLocation = localStorage.getItem("physicalLocationData");
      if (savedLocation) {
        setLocationData(JSON.parse(savedLocation));
      }
    }

    const handlePlaceDataChange = () => {
      const updatedLocation = localStorage.getItem("physicalLocationData");
      if (updatedLocation) {
        setLocationData(JSON.parse(updatedLocation));
      }
    };

    window.addEventListener("placeDataChanged", handlePlaceDataChange);
    return () => {
      window.removeEventListener("placeDataChanged", handlePlaceDataChange);
    };
  }, []);

  return (
    <header className="sticky top-0 z-50 w-full">
      {/* Date and Location Bars */}
      <DateInfoBar locationData={locationData} />
      <GeolocationBar />

      {/* Main Header Section */}
      <div className="w-full bg-sky-50 border-b border-sky-50">
        <div className="max-w-[1920px] mx-auto px-4 sm:px-6 lg:px-8 py-2">
          <div className="flex flex-col md:grid md:grid-cols-[auto_1fr_auto] md:items-center gap-3">
            {/* Logo */}
            <div className="flex-shrink-0">
              <Logo />
            </div>

            {/* Search Bar - Centered on desktop */}
            <div className="flex justify-center">
              <div className="w-full text-sm">
                <SearchBar />
              </div>
            </div>

            {/* Empty div for balanced spacing */}
            <div className="hidden md:block flex-shrink-0" />
          </div>
        </div>
      </div>
    </header>
  );
}