'use client';

import React, { createContext, useContext } from 'react';

// Create a simple context that doesn't use nonces
const NonceContext = createContext<null>(null);

// Simplified provider component
export function NonceProvider({ children }: { children: React.ReactNode }) {
  // This component is kept for compatibility but now just passes through children
  return (
    <NonceContext.Provider value={null}>
      {children}
    </NonceContext.Provider>
  );
}

// Hook preserved for API compatibility but returns nothing
export function useClientNonce() {
  return null;
}